<template>
  <section class="about-section">
    <div class="row align-items-center mb-4">
      <div class="col-12 text-center position-relative">
        <img src="@/assets/openflatsBack.png" class="img-fluid" alt="Équipe Le Moon">
        <h1 class="text-white position-absolute top-50 start-50 translate-middle">À propos</h1>
      </div>
    </div>
  </section>
  <div class="container bg-white">
    <div class="row align-items-center">
      <div class="col-md-4 mb-4 mb-md-0">
        <h1>Réinventer l'hébergement.</h1>
      </div>
      <div class="col-md-4 mb-4 mb-md-0">
        <p>Héberger des voyageurs Airbnb dans nos domiciles personnels est une passion depuis 2015. Echanger avec eux
          nous a permis de comprendre la raison pour laquelle ils préféraient séjourner en appartement plutôt qu'à
          l'hôtel : une convivialité sans pareil, des espaces de vie pour se retrouver et échanger après une belle
          journée.</p>
      </div>
      <div class="col-md-4">
        <p>Le Moon a été créé afin de répondre à ce marché grandissant : proposer un nouveau type de
          logements - 100% légal - à ces voyageurs en quête d’un nouveau standard en matière d’hospitalité sur la Côte d'Azur. Nous avons
          mis au point une expérience d’hébergement dont nous sommes fiers et que nous avons de cesse de
          perfectionner.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Qui-sommes-nous"
}
</script>

<style scoped>
/* Ajoutez vos styles CSS personnalisés ici */
.text-white {
  color: #fff;
}

.about-section {
  background-color: white;
  max-height: 100vh;
  overflow: hidden;
}

.container {
  padding: 0;
}

.position-relative {
  position: relative;
  padding: 0;
}

.position-absolute {
  position: absolute;
}

.top-50 {
  top: 50%;
}

.start-50 {
  start: 50%;
}

.translate-middle {
  transform: translate(-50%, -50%);
}
</style>
