<template>
  <section class="section text-white pt-5 pb-5">
    <div class="container-fluid col-md-11">
      <div class="card">
        <div class="card-body d-md-flex">
          <div class="col-md-3">
            <a class="navbar-brand mb-5" href="/">
              <img src="@/assets/Logo_moon.png" class="card-img" alt="logo le moon">
            </a>
            <div class="d-flex align-items-center mb-3">
              <a href=""><span class="fab fa-instagram fa-3x text-white mr-3"></span></a>
              <!-- Add LinkedIn and Facebook icons and links -->
              <a href="" target="_blank"><span class="fab fa-linkedin fa-3x text-white mr-3"></span></a>
              <a href="" target="_blank"><span class="fab fa-facebook fa-3x text-white"></span></a>
            </div>
            <p class="text-white">Le Moon propose la meilleure expérience d’hébergement pour vos vacances ou vos séjours professionnels. Les meilleurs appartements et le meilleur service.</p>
            <router-link to="/proprietaire">
              <button class="btn btn-outline-success custom-btn" type="submit">Nous contacter</button>
            </router-link>
            <p class="text-white mt-3">
              <i class="fas fa-phone mr-2"></i>
              <a href="tel:+3372512325" class="text-white">09 72 51 23 25</a>
            </p>
          </div>
          <div class="col-md-9 mt-5">
            <div class="row justify-content-end">
              <div class="col-md-3">
                <h4 class="text-white">Les villes</h4>
                <ul class="list-unstyled">
                  <router-link to="/destinations/1">
                    <li><a class="footer-link" href="/destinations/1">Nice</a></li>
                  </router-link>
                  <router-link to="/destinations/2">
                    <li><a class="footer-link" href="/destinations/2">Saint Paul de Vence</a></li>
                  </router-link>
                  <router-link to="/destinations/3">
                    <li><a class="footer-link" href="/destinations/3">Cannes</a></li>
                  </router-link>
                </ul>
              </div>
              <div class="col-md-3">
                <h4 class="text-white">Entreprise</h4>
                <ul class="list-unstyled">
                  <router-link to="/Qui-sommes-nous">
                    <li><a class="footer-link" href="/Qui-sommes-nous">À propos</a></li>
                  </router-link>
                  <router-link to="/Questions">
                    <li><a class="footer-link" href="/Questions">Aide et FAQ</a></li>
                  </router-link>
                  <router-link to="/Conditions">
                    <li><a class="footer-link" href="/Conditions">Conditions d'utilisations</a></li>
                  </router-link>
                  <router-link to="/MentionsLegales">
                    <li><a class="footer-link" href="/MentionsLegales">Mentions Legales</a></li>
                  </router-link>
                </ul>
              </div>
              <div class="col-md-3">
                <h4 class="text-white">Coordonnées</h4>
                <ul class="list-unstyled">
                  <li class="text-white">Adresse: <a class="footer-link" href="https://maps.app.goo.gl/akZBkKWsEitYu2Jo7" target="_blank">23 rue des Lombards, 75004 Paris</a></li>
                  <li class="text-white">Email: <a class="footer-link" href="mailto:contact@openflats.fr">contact@openflats.fr</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer text-muted d-flex justify-content-end">
          <div class="text-white">© 2023 Bakjy Group. Tous droits réservés.</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Footer"
}
</script>

<style scoped>
.card-img {
  height: 5rem;
}

h3 {
  font-size: 28px; /* Increased font size */
  font-weight: 700;
}

h4 {
  font-size: 24px; /* Increased font size */
  font-weight: 600;
}

.card {
  border: none;
  background-color: #3082b8;
  border-radius: 25px;
}

.card-footer {
  border: none;
  background-color: #3082b8;
  border-radius: 25px;
}

.section {
  background-color: #3575A0;
}

.custom-btn {
  color: white;
  border-color: white;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

.custom-btn:hover {
  background-color: white;
  color: #3575A0;
}

a {
  color: white;
  text-decoration: none; /* Removed underline */
}

.footer-link:hover {
  text-decoration: underline; /* Underline on hover */
  transition: color 0.3s, text-decoration 0.3s;
}

a.router-link-exact-active {
  background: none;
}

/* Add space between links */
.list-unstyled li {
  margin-bottom: 8px;
}
</style>
