<template>
  <NavBar/>
  <Search/>
  <Misenavant/>
  <Type/>
  <Zone/>
  <Satisfaction/>
  <Avis/>
  <Footer/>
</template>

<script>

import Search from "@/components/Search";
import Type from "@/components/Type";
import Zone from "@/components/Zone";
import Satisfaction from "@/components/Satisfaction";
import Avis from "@/components/Avis";
import Footer from "@/components/Footer";
import NavBar from "@/components/Navbar";
import Misenavant from "@/components/Misenavant";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Home',
  components: {
    Misenavant,
    NavBar,
    Footer,
    Avis,
    Satisfaction,
    Zone,
    Type,
    Search
  }
}
</script>

<style>

body{
  background-color: #f8f9fa!important;
}

p{
  font-size: 1rem;
}


</style>
