<template>
  <section>
    <div class="container-fluid col-md-11 text-center">
      <h1 class="title">Nos valeurs</h1>
      <p>Notre approche unique de l’hospitalité et du confort se retranscrit
        dans chaque espace que nous proposons et service que nous fournissons</p>
      <div class="row align-items-center">
        <div class="col-md-6">
          <img src="https://book.guestready.com/_next/image?url=%2Fassets%2Fimages%2FaboutUs%2Finfo_2.jpeg&w=2048&q=75"
               alt="Image" class="img-fluid">
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6">
              <h2 class="subtitle">Harmonie</h2>
              <p>Nos architectes ont conçu nos espaces pour offrir une expérience d'hébergement au-delà de votre
                imagination</p>
              <i class="fa-solid fa-handshake"></i>
            </div>
            <div class="col-md-6">
              <h2 class="subtitle">Modernité</h2>
              <p>Nos espaces sont proposés entièrement équipés avec les commodités d'un appartement moderne

              </p>
              <i class="fa-solid fa-fire"></i>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <h2 class="subtitle">Service</h2>
              <p>L’équipe Guest Happiness OpenFlats est à votre disposition durant votre hébergement en cas de besoin
                7jours / 7 - 9h à 22h</p>
              <i class="fa-solid fa-bullseye"></i>
            </div>
            <div class="col-md-6">
              <h2 class="subtitle">Experience</h2>
              <p>Immergez-vous dans la culture locale grâce aux bons plans que nous vous recommandons.</p>
              <i class="fa-solid fa-graduation-cap"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Valeurs2"
}
</script>

<style scoped>
section {
  background-color: white;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.title {
  font-size: 3rem;
  font-weight: 600;
  color: #3575A0;
}

.img-fluid {
  border-radius: 25px;
}

.fa-solid {
  font-size: 5rem;
  color: #3575A0;
  font-weight: 900;
}

.row {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.subtitle {
  margin-bottom: 1rem;
}
</style>
