<template>
  <NavBar/>
  <div class="container conditions-utilisation">
    <article>
      <h1 class="mb-4">Conditions Générales de Vente (version à jour au 25.03.2024)</h1>

      <section>
        <h2>Préambule</h2>
        <p>Les présentes conditions Générales de Vente Le Moon (les ” Conditions Générales ‘’) régissent les
          relations entre la société Le Moon, société par actions simplifiée au capital de 227.700 euros,
          immatriculée au registre du commerce et des sociétés de Paris sous le numéro 834 117 533 dont le siège social
          est situé au 23, Rue des Lombards, 75004 Paris, France (ci-après ” Le Moon ” ou ” nous “) et toute personne
          désireuse de réserver (l’ Utilisateur ” ou ‘’ voyageur ‘’ ou ” vous “) un de nos appartements via notre site
          internet (le “site internet Le Moon”).</p>
        <p>Le Moon et l’Utilisateur sont ci-après dénommés individuellement une « Partie » et collectivement les «
          Parties ».</p>
        <p>Votre réservation vous permet de profiter d’Le Moon, un service de location d’appartements pour de la
          location saisonnière disponible en région Parisienne.</p>
      </section>

      <section>
        <h2 class="mt-4">Article 1. Votre réservation</h2>
        <p>1.1. Pour bénéficier des services proposés par Le Moon, vous devez impérativement (i) être majeur ; (ii)
          ne pas organiser dans le logement et ses extérieurs de soirées ou d’évènements nuisant à la tranquillité du
          voisinage ; et (iii) ne pas avoir eu plus de deux (2) litiges au cours des trois (3) dernières années sur les
          principales plateformes de location saisonnière (4) restituer le logement dans un bon état de propreté…</p>
        <p>1.2. Vous devez compléter le formulaire de réservation sur le site internet Le Moon (et les autres sites
          sur lesquels figurent nos annonces) en renseignant les champs au moyen de données complètes et exactes. En cas
          de déclaration incomplète ou erronée, la réservation de l’Utilisateur peut être annulée, de plein droit, sans
          préavis et sans formalité préalable et l’Utilisateur consent à garantir Le Moon de toutes les conséquences
          qui pourraient découler de cette transmission de données incomplète ou erronée.</p>
        <p>1.3. Afin de recevoir les confirmations de vos réservations et les messages d’information concernant vos
          locations, vous devrez nous fournir une adresse e-mail valide et votre numéro de téléphone portable.</p>
        <p>1.4. Au cours de votre réservation, il vous sera demandé de nous transmettre, via le site internet Open
          Flats, les informations suivantes :</p>
        <ul>
          <li>Nom et prénom ;</li>
          <li>Adresse e-mail ;</li>
          <li>Numéro de téléphone ;</li>
          <li>Informations de paiement ;</li>
          <li>Photocopie valide de la pièce d’identité.</li>
        </ul>
        <p>1.5. Toutes les informations que vous nous fournissez doivent être valables au moment de chaque location
          d’appartement et pour toute la durée de ladite location. Tous ces éléments doivent vous être personnels et
          faire apparaître votre identité.</p>
        <p>1.6. La validation de votre location ne sera effective qu’après vérification de votre identité. A défaut de
          prendre part à cette vérification d’identité et/ou si Le Moon n’est pas en mesure de vérifier votre
          identité, votre location sera suspendue pour n’être validée qu’après validation de votre identité par tout
          autre moyen proposé par Le Moon.</p>
        <p>1.7. En louant des logements proposés par Le Moon, vous reconnaissez avoir pris connaissance des
          Conditions Générales et Particulières communiquées lors du processus de réservation en ligne et acceptez de
          vous y conformer. Chaque nouvelle location de logement entraînera une réitération de votre acceptation des
          termes des Conditions Générales et Particulières.</p>
        <p>A ce titre vous reconnaissez et acceptez qu’en cas de non-respect des stipulations des Conditions Générales
          et Particulières, Le Moon est susceptible de vous appliquer une pénalité dont les montants sont visés en
          Annexe A (“Charges additionnelles”).</p>
        <p>Vous garantissez détenir tous les droits nécessaires àl’utilisation des informations transmises (y compris
          les informations bancaires) dans le cadre de votre réservation et concédez un droit d’utilisation à Le Moon
          dans le cadre de votre location ainsi que dans le cadre de toute action de promotion des services utilisés par
          Le Moon (par internet, télévision et/ou sur support papier). Cette autorisation est valable pour la durée
          de votre location à Le Moon et vise tant les éléments soumis à des droits de propriété intellectuelle que
          ceux qui relèvent de la protection de votre vie privée et de votre droit à l’image.</p>
      </section>
      <section>
        <h2 class="mt-4">Article 2. Effectuer une réservation</h2>
        <p>2.1. Vous pouvez réserver un logement via le site internet Le Moon <a href="https://www.moonlocation.com/">https://moonlocation.com/</a>
        </p>
        <p>2.2. Vous devez réserver le logement avant de pouvoir y accéder. Lors de votre réservation, il vous sera
          proposé un large choix de logements (appartements, maisons…), selon les disponibilités au regard de la période
          concernée et du lieu choisi. Dans l’éventualité où pour des évènements indépendants de notre volonté, le
          logement que vous avez réservé ne serait plus disponible durant les dates que vous avez choisies, Le Moon
          s’engage à faire le nécessaire pour vous trouver sur les mêmes dates un logement au moins équivalent (confort,
          superficie, localisation) à celui pour lequel vous avez effectué une réservation.</p>
        <p>2.3. Vous pouvez réserver le logement jusqu’à douze (12) mois à l’avance et dans la limite de dix (10)
          réservations simultanées au maximum.</p>
        <p>2.4. Le paiement de la location s’effectue en prépaiement, à savoir au moment de la confirmation de la
          réservation. Ce prépaiement couvre le montant de votre location (en fonction du nombre de jours souhaités),
          diverses taxes et frais applicables, à l’exclusion des coûts additionnels qui surviendraient durant la période
          de location. La carte bancaire que vous avez sélectionnée au moment de la réservation sera débitée du montant
          indiqué. Vous recevrez alors un courriel de confirmation de réservation incluant un reçu. Par ailleurs, le
          montant prépayé sera mentionné sur la facture définitive et déduit du montant total restant éventuellement à
          payer.</p>
        <p>2.5. La carte bancaire utilisée pour effectuer la réservation doit être au nom et prénom du titulaire du
          compte, qui doivent correspondre aux informations figurant sur la pièce d’identité. Si vous n’utilisez pas une
          carte en votre nom et prénom, nous nous réservons le droit d’annuler votre réservation et de facturer votre
          carte de paiement enregistrée pour toute perte encourue.</p>
        <p>2.6. Au moment de la confirmation de réservation, et pour des raisons de sécurisation des paiements, nous
          avons mis en place le système 3D Secure. Ainsi, sous réserve que votre établissement bancaire soit équipé du
          protocole 3D Secure, au moment du paiement, vous serez redirigé vers un site de votre banque où il vous sera
          demandé de vous authentifier à l’aide d’une donnée personnelle préalablement définie avec votre banque (les
          méthodes d’authentification sont propres à chaque banque). Après validation de cette saisie et accord de votre
          banque, le paiement sera validé et votre réservation confirmée. Cette authentification est obligatoire pour
          confirmer votre réservation. A défaut d’une authentification ou en cas d’échec, votre réservation ne sera pas
          confirmée. Le Moon se réserve le droit de bloquer un compte suite à plusieurs échecs d’authentification au
          3DSecure.</p>
        <p>2.7. Au moment de chaque réservation, vous autorisez Le Moon à vérifier la validité de votre carte
          bancaire et à débiter celle-ci du montant de chacune des locations ainsi que des frais additionnels éventuels.
          Vous reconnaissez être informé et acceptez que nous nous réservions le droit de vous débiter sur la même carte
          bancaire utilisée (et/ou dans le plafond de débit attaché à ladite carte) le montant correspondant à
          l’autorisation de prélèvement communiqué avant la finalisation de la location.</p>
        <p>Le montant réservé ne sera débité par Le Moon qu’en cas de dommage causé au logement et/ou en cas
          d’incident de paiement lors du versement du montant correspondant à votre location. Vous êtes informé et
          acceptez que, pour des raisons techniques notamment liées à la durée de votre réservation, Le Moon puisse
          être amenée à renouveler l’autorisation de paiement.</p>
        <p>2.8. Vous pouvez utiliser le site internet Le Moon si vous souhaitez prolonger votre location, cette
          prolongation sera néanmoins sujette à conditions.</p>
        <p>2.9. Chaque jour de prolongation sera facturé au tarif maximum en vigueur.</p>
        <p>2.10. Vous recevrez une confirmation de votre réservation par e-mail sur l’adresse communiquée lors de votre
          réservation.</p>
      </section>
      <section>
        <h2>Article 3. Autorisation de prélèvement</h2>

        <h3>3.1. Pré-autorisation bancaire</h3>
        <p>Avant le début de la location, Le Moon procédera à une pré-autorisation bancaire correspondant à une somme
          arrêtée par la société (entre 300€ et 1 500€) après jonction de différents critères : taille de l’appartement,
          le nombre de couchage et sa localisation.</p>

        <p>Le montant de l’autorisation de prélèvement est communiqué au voyageur avant le paiement de la location afin
          qu’il dispose de toutes les informations nécessaires pour que son consentement soit parfaitement libre et
          éclairé. Il est donc nécessaire que votre carte bancaire soit valide à la date de fin de location prévue et
          dispose de fonds disponibles suffisants. L’autorisation de prélèvement doit être effectuée sur la même carte
          bancaire que celle utilisée pour le paiement de la location. Le montant de l’autorisation de prélèvement est
          retenu sur votre carte bancaire mais n’est pas prélevé.</p>

        <h3>3.2. Blocage de la somme</h3>
        <p>L’autorisation de prélèvement permet de bloquer une somme d’argent sur votre compte bancaire mais n’est pas
          encaissé en début de location. Il a pour but de couvrir les coûts additionnels et dommages éventuels durant
          votre location. En fonction de votre établissement bancaire, il est possible que ce montant apparaisse comme
          déduit de votre compte ce qui n’est pas réellement le cas. Il est tout simplement « bloqué » jusqu’à ce qu’un
          paiement final intervienne. La société se réserve le droit de prélever le montant correspondant aux différents
          coûts afférents à la remise en état de l’appartement. La somme sera donc EFFECTIVEMENT DEBITÉE si, à la
          restitution de l’appartement, l’Utilisateur, doit à Le Moon des frais liés à la réparation de tous types de
          dommages provoqués.</p>

        <h3>3.3. Confirmation de la réservation</h3>
        <p>La veille du début prévu de votre location, Le Moon procédera à l’autorisation de prélèvement sur votre
          carte bancaire. Si l’autorisation est acceptée par votre banque, votre réservation est confirmée. En cas de
          refus par votre banque, votre réservation sera mise en attente. Vous disposerez ainsi de quelques heures pour
          pouvoir contacter votre banque. Sans retour de votre part le jour du début prévu de votre location, votre
          location sera automatiquement annulée.</p>

        <h2>Article 4. Dépôt de garantie</h2>
        <p>Pour toute réservation supérieure à 5 nuitées consécutives, Le Moon prélève un dépôt de garantie sur les
          mêmes critères que l’autorisation de prélèvement, qui sera intégralement restitué le lendemain du départ, sauf
          à devoir couvrir des coûts additionnels tels que précisés dans l’article 3.2 des présentes Conditions
          Générales de Vente.</p>

        <h2>Article 5. Modification / annulation d’une réservation</h2>

        <h3>5.1. Modification de réservation</h3>
        <p>Vous pouvez modifier une réservation jusqu’à trente (30) jours avant le début prévu de la location, sous
          réserve de disponibilités, en contactant le Service Client. La modification peut porter sur tout ou partie de
          la réservation, à savoir le logement objet de la réservation, les dates de début et de fin et les options.</p>

        <h3>5.2. Modification de prix</h3>
        <p>Toute modification de votre réservation peut donner lieu à une modification de prix, puisque la réservation
          sera modifiée en fonction des prix en vigueur à la date de la modification. Si vous avez bénéficié d’une offre
          promotionnelle ou d’une remise lors de votre réservation initiale, et que votre modification ne respecte plus
          les critères vous donnant droit à cette offre ou à cette remise, alors la promotion ne s’appliquera plus.
          Aucune compensation ne pourra être alors demandée.</p>

        <h3>5.3. Paiement du solde</h3>
        <p>Au moment de la confirmation de la modification de votre réservation, si le nouveau montant de votre
          réservation est :</p>
        <ul>
          <li>Supérieur à la somme que vous avez déjà payée, vous devrez payer le solde correspondant ;</li>
          <li>Inférieur à la somme déjà payée, vous serez alors remboursé de la différence.</li>
        </ul>

        <h3>5.4. Confirmation par courriel</h3>
        <p>Vous recevrez une confirmation de la modification de votre réservation par courriel, précisant les nouvelles
          modalités de votre réservation, ainsi que le nouveau montant total payé.</p>

        <h3>5.5. Annulation de réservation</h3>
        <p>Il vous sera impossible de modifier votre location trente (30) jours avant le début de celle-ci sans frais
          supplémentaires. Passé ce délai, si vous ne pouvez plus honorer votre location pour quelque raison que ce
          soit, vous aurez la possibilité d’annuler votre réservation, mais aucun remboursement ne vous sera effectué à
          l’exception des frais de ménage et autres taxes de séjour. Si vous choisissez néanmoins d’annuler votre
          location, un courriel vous sera envoyé confirmant votre annulation et récapitulant les sommes versées.</p>
      </section>
      <section>
        <h2>Article 6. Mise à disposition du logement</h2>

        <h3>6.1. État du logement</h3>
        <p>Le logement est mis à votre disposition propre et nettoyé, la literie et les serviettes sont inclus dans le
          prix de la location sous réserve des stipulations suivantes ou, le cas échéant, des offres commerciales
          applicables.</p>

        <p>6.2. A toutes fins utiles, il est rappelé que vous êtes responsable du logement à compter de sa mise à
          disposition par Le Moon, jusqu’au lendemain du passage de nos équipes de ménage, chargées de réaliser un
          état des lieux de sortie.</p>

        <h2>Article 7. Arrivées autonomes</h2>

        <h3>7.1. Informations pour l'accès</h3>
        <p>Une fois votre location confirmée, le paiement et, dans le cas d’une réservation supérieure à 5 nuitées
          consécutives, le dépôt de garantie prélevé, Le Moon vous fera parvenir par courriel toutes les informations
          nécessaires pour vous permettre d’accéder au logement de manière autonome.</p>

        <h3>7.2. Sécurité des clés</h3>
        <p>Vous ne devez en aucun cas communiquer les clés, badges ou toute autre information d’accès à des tiers leur
          permettant d’accéder aux logements Le Moon. Tout problème ou dégradation qui en résulterait sera considérée
          comme ayant été réalisée par vous.</p>

        <h3>7.3. Contact en cas d'urgence</h3>
        <p>En cas d’urgence ou de problème lors de l’accès au logement de votre location, vous pouvez contacter le
          service client Le Moon au +33 9 72 51 23 25 ou à guest@openflats.fr.</p>

        <h2>Article 8. Départ anticipé/annulation</h2>
        <p>Si vous souhaitez rendre le logement de manière anticipée, vous pouvez annuler votre réservation. Une
          restitution anticipée du logement est toujours possible sans frais, mais n’engendrera pas de remboursement ou
          d’arrangement sur le montant total de la location.</p>

        <h2>Article 9. Conditions d'utilisation du logement</h2>

        <h3>9.1. Nombre de personnes</h3>
        <p>L’Utilisateur doit indiquer au moment de la location le nombre de personnes qui vont séjourner avec lui dans
          le logement. Une fois la réservation confirmée, si ce nombre devait être revu à la hausse, vous vous devez
          d’en informer Le Moon au moins 72 heures à l’avance.</p>

        <h3>9.2. Responsabilité pour dommages</h3>
        <p>L’Utilisateur doit veiller à ne faire subir au logement et à tous les objets qui s’y trouvent aucun dommage
          d’aucune sorte. En cas de dommage, vous devez en informer Le Moon dans les meilleurs délais par courriel à
          l’adresse suivante : guest@openflats.fr ou par téléphone au +33 9 72 51 23 25.</p>

        <p>Selon le montant du dommage subi, l’autorisation de prélèvement acceptée au moment de la réservation pourra
          être saisie, en partie ou en totalité. Et si le montant du dommage venait à être supérieur au montant du
          prélèvement, un paiement supplémentaire pourrait être demandé en dédommagement, et vous vous engagez à
          l’honorer.</p>

        <p>Tout dommage causé au logement par vous ou l’un de vos tiers sera considéré comme ayant été réalisé par
          l’Utilisateur qui a effectué la réservation.</p>

        <h3>9.3. Interdictions dans le logement</h3>
        <p>Il est strictement interdit d’organiser dans le logement et ses extérieurs (jardin, terrasse, balcon…), des
          soirées, des fêtes, de regroupements de personnes ou toute autre activité venant nuire à la tranquillité du
          voisinage. Il est également interdit d’exploiter les parties communes réservées aux habitants de l’immeuble.
          En cas d’infraction, vous en serez personnellement tenus pour responsable et de nombreuses sanctions
          pourraient être prises à votre encontre, pouvant aller jusqu’à l’interruption de votre séjour sans
          remboursement des frais avancés et, si la situation l’oblige, faire intervenir les forces de l’ordre sans
          délais.</p>

        <h3>9.4. Non-fumeur et non-vapoteur</h3>
        <p>Le logement qui vous est proposé est strictement non-fumeur et vapoteur. Vous ne devez pas fumer et/ou
          vapoter ni laisser qui que ce soit fumer/vapoter dans le logement.</p>

        <h3>9.5. Animaux interdits</h3>
        <p>Les animaux de toutes sortes sont strictement interdits dans le logement.</p>

        <h3>9.6. Restitution du logement</h3>
        <p>N’oubliez pas qu’un autre client Le Moon utilisera le logement après vous et que vous devez donc le
          restituer sans dégradations, comme si il s’agissait de votre propre logement.</p>

        <h3>9.7. Panne ou anomalie</h3>
        <p>Dans l’éventualité où une panne ou une anomalie (électricité, plomberie, chauffage…) surviendrait dans le
          logement pendant votre séjour, prévenez-nous immédiatement. Ne faites pas appel à un service de dépannage
          indépendant et ne laissez personne intervenir dans le logement ou le réparer sans notre autorisation
          expresse.</p>

        <h3>9.8. Cambriolage ou vandalisme</h3>
        <p>Si le logement est cambriolé ou vandalisé, veuillez impérativement (i) le signaler à la police dans un délai
          maximum de quarante-huit (48) heures ; et (ii) nous prévenir immédiatement au +33 9 72 51 23 25.</p>

        <h3>9.9. Utilisation interdite pour :</h3>
        <p>L’utilisation du logement est interdite pour les cas suivants :</p>
        <ul>
          <li>Pour l’hébergement de personnes à titre onéreux ;</li>
          <li>Pour l’hébergement de personnes impliquées dans toute activité illicite ou terroriste ;</li>
          <li>Pour abriter des substances inflammables, corrosives, toxiques, explosives, ionisantes ou encombrants qui
            pourraient endommager le logement.
          </li>
        </ul>
      </section>
      <section>
        <h2>Article 10. Restitution du logement</h2>

        <h3>10.1. Heure de restitution</h3>
        <p>Vous devez restituer le logement à l’heure convenue. Une marge de trente (30) minutes après l’heure de fin de
          contrat est tolérée après accord du Service Client. En dehors de ce créneau et de toute autorisation expresse,
          des frais de restitution pourront s’appliquer.</p>

        <h3>10.2. État de propreté</h3>
        <p>Vous devez rendre le logement propre dans un état suivant une utilisation dite « normale ». En cas de mauvais
          état du logement au retour, Le Moon se réserve le droit de vous facturer des frais de nettoyage
          supplémentaires.</p>

        <h3>10.3. Déclaration des dommages</h3>
        <p>Au moment de la restitution, vérifier l’état du logement et déclarer les dommages subis par le logement
          lorsque que vous en disposiez par courriel à l’adresse suivante : guest@openflats.fr. Si les éventuels dégâts
          ne sont pas déclarés avant la fin de la location, nous serons en mesure de vous facturer une majoration afin
          de couvrir le préjudice subi par Le Moon au-delà des seuls dégâts matériels du logement.</p>

        <h3>10.4. Restitution des clés</h3>
        <p>N’oubliez pas de laisser les clés dans le boitier autonome dont la localisation et son utilisation sont
          précisées par nos services au moment de la réservation, ainsi que l’ensemble des documents et matériels qui
          ont été mise à votre disposition au début de la location et de verrouiller le logement en claquant la porte
          derrière vous. En cas de non-restitution des clés, une Pénalité vous sera appliquée.</p>

        <h3>10.5. Vérification après la location</h3>
        <p>Avant que le logement qui vous a été confié soit loué de nouveau à un autre Utilisateur, il est vérifié par
          Le Moon qui s’assurera du respect par l’Utilisateur des termes des Conditions Générales de Vente et
          Conditions Particulières.</p>

        <h3>10.6. Responsabilité post-restitution</h3>
        <p>Vous restez responsable du logement jusqu’au passage de nos équipes de ménage.</p>

        <h3>10.7. Pénalités de restitution</h3>
        <p>En cas de non-restitution du logement à l’heure prévue de fin de location, et sans extension de location
          validée par Le Moon, l’Utilisateur se verra appliquer des Pénalités de restitution, en sus du tarif de
          location en vigueur jusqu’à restitution du logement.</p>

        <h3>10.8. Expulsion et actions judiciaires</h3>
        <p>Le Moon se réserve le droit d’expulser un Utilisateur pour ce type de comportement. Le Moon se réserve
          également la possibilité d’engager toute action judiciaire à l’encontre de l’Utilisateur fautif (en ce compris
          un dépôt de plainte). Dans ce cas, Le Moon se réserve le droit de vous réclamer un jour supplémentaire pour
          chaque jour de location, au tarif de location en vigueur, le paiement d’un montant fixe de six-cent cinquante
          (650) euros TTC par jour pendant lequel le logement aura été retenu au-delà de la période de location, ainsi
          que l’indemnisation de tous les dommages et tous les préjudices subis par Le Moon (notamment amendes,
          pénalités ou coûts de procédure).</p>

        <h2>Article 11. Conditions financières</h2>

        <h3>11.1. Frais de location du logement</h3>
        <p>Les tarifs pour chaque location seront calculés au moment où vous faites la réservation et s’afficheront sur
          le site internet Le Moon avant validation de votre part (incluant les éventuels suppléments applicables, en
          ce compris les frais de ménage ou de taxe de séjour).</p>

        <p>Le prix de la location du logement se calcule en fonction des dates et horaires de début et de fin souhaités
          pour la location, du jour de la semaine et de la durée de la location. Votre carte bancaire sera débitée au
          moment de la réservation pour les frais de location et les charges additionnelles éventuellement applicables
          seront facturées en fin de location.</p>
        <p>La société procédera également à l’autorisation de paiement visée à l’article 3 des présentes conditions
          générales.</p>

        <p>La réservation sera seulement confirmée quand l’autorisation de prélèvement aura été acceptée par votre
          banque. Nous vous avertirons si cette dernière est refusée par votre banque.</p>

        <p>La location est considérée comme toujours en cours jusqu’à la restitution des clés du logement. Si cette
          durée dépasse la période initialement prévue, Le Moon vous facturera des frais additionnels.</p>

        <h3>11.2. Frais additionnels</h3>
        <p>En cas de dommages causés au logement, vous serez facturé des coûts de réparations, de l’indemnité
          d’immobilisation ainsi que des coûts éventuels de gestion du dommage. Le Moon vous transmettra dans les
          plus brefs délais une estimation de ces coûts de réparations ou de l’indemnité d’immobilisation par courriel.
          Et ces coûts vous seront facturés dans un délai de sept (7) jours. Si le logement est rendu dans un mauvais
          état de propreté, il vous sera facturé un montant forfaitaire correspondant au nettoyage dudit logement.</p>

        <h3>11.3. Objets personnels oubliés</h3>
        <p>En cas d’oubli d’objets personnels dans le logement, et si ces objets sont récupérés par Le Moon, ils
          peuvent vous être renvoyés à votre domicile moyennant des frais administratifs de dix euros (10 €) TTC nets en
          plus des frais d’envoi. Le Moon décline toute responsabilité sur les objets trouvés et tout objet sera jeté
          et/ou donné à une association 60 jours après la fin de la location.</p>

        <h3>11.4. Facturation et prélèvement automatique</h3>
        <p>Tous les frais additionnels sont mentionnés sur la facture qui sera établie à l’issue de la location et le
          montant correspondant sera prélevé automatiquement avec le prix de la location.</p>

        <h3>11.5. Engagement de paiement</h3>
        <p>En réservant un logement, vous vous engagez à payer à Le Moon tous les frais liés à la location (et ce
          compris les éventuels frais additionnels) et à prendre en charge tous les coûts liés à votre utilisation du
          logement dont les dommages éventuellement causés de votre fait ou de celui de vos tiers. Vous devez nous
          informer de tout changement relatif à votre carte bancaire. Dans le cas de l’utilisation d’une carte bancaire
          non valide (e.g., périmée, annulée), vous reconnaissez et acceptez que Le Moon est libre d’annuler
          immédiatement et sans préavis tous les contrats de location concernés. À chaque fin de location, nous
          débiterons votre compte des éventuels frais additionnels survenus lors de la réservation.</p>

        <h3>11.6. Surcharge bancaire</h3>
        <p>Nous ne serons pas responsables de toute surcharge, frais ou coût additionnel que votre établissement
          bancaire est susceptible de mettre à votre charge.</p>

        <h3>11.7. Refus de paiement</h3>
        <p>En cas de refus de l’autorisation de paiement ou du paiement du montant de la location par votre banque, nous
          nous réservons la possibilité de suspendre votre réservation et d’annuler immédiatement toute réservation de
          logement que vous auriez effectuée.</p>
      </section>
      <section>
        <h2>Article 12. Protection des données à caractère personnel</h2>

        <h3>12.1. Collecte de données à caractère personnel</h3>
        <p>Le Moon, dans ses relations avec l’Utilisateur, est amenée à traiter, pour son propre compte, des données
          à caractère personnel de l’Utilisateur, lesquelles données (i) lui ont été communiquées par ce dernier lors de
          sa réservation ou (ii) ultérieurement par courriel aux équipes Le Moon. Par ailleurs, l’Utilisateur est
          informé que des mentions légales sont à sa disposition sur le site internet de la société.</p>
        <p>Ces informations sont nécessaires pour permettre à l’Utilisateur d’accéder aux services proposés par Open
          Flats. En outre, elles sont utilisées par Le Moon afin de lui permettre d’améliorer les services qu’elle
          propose.</p>
        <p>Les données dont la communication à Le Moon est obligatoire sont identifiées comme telles lors de la
          réservation du logement. L’Utilisateur a préalablement consenti aux traitements désignés dans les Conditions
          Générales, au moment de la réservation du logement en cliquant sur le bouton « réserver ».</p>
        <p>Sauf sur demande ou avec l’accord exprès de l’Utilisateur et dans le strict respect de ses directives, Open
          Flats ne procèdera à aucun autre traitement de données à caractère personnel autre que ceux décrits au présent
          Article 12.</p>

        <h3>12.2. Catégories des données à caractère personnel</h3>
        <p>Lors de la réservation de l’Utilisateur sur le site Le Moon, Le Moon collecte des données
          d’identification de l’Utilisateur (par ex. nom, prénom, adresse courriel, numéro de téléphone) ainsi que des
          données personnelles relatives à ses moyens de paiement (par ex. numéros, date d’expiration et cryptogramme de
          la carte bancaire de l’Utilisateur).</p>
        <p>Lors de la réservation du logement par l’Utilisateur, Le Moon collecte des informations relatives à la
          transaction réalisée (numéro de la transaction, factures émises).</p>
        <p>Plus généralement, Le Moon collecte et traite des données relatives au suivi de sa relation commerciale
          avec l’Utilisateur (p. ex. adresse de facturation, nombre de réservations réalisées), à l’organisation de jeux
          concours et de loteries publicitaires ou encore à la réalisation d’actions de fidélisation. Enfin, lorsque
          l’Utilisateur accède au site Le Moon, des cookies sont susceptibles d’être déposés afin de collecter et
          traiter des informations relatives à votre à la navigation de l’Utilisateur.</p>

        <h3>12.3. Finalité du traitement</h3>
        <p>Les données vous concernant sont collectées par Le Moon :</p>
        <ul>
          <li>En vue de l’utilisation du site internet Le Moon et dans le cadre des locations de logements ;</li>
          <li>Pour effectuer des opérations relatives à sa relation commerciale avec vous, c’est-à-dire concernant les
            commandes, les livraisons, les factures, la comptabilité ;
          </li>
          <li>Pour opérer le suivi de la « relation client », tel que la réalisation d’enquêtes de satisfaction ;</li>
          <li>Pour vous proposer des offres de partenaires en fonction de vos choix, à condition que vous y ayez
            expressément consenti ;
          </li>
          <li>Pour vous tenir au courant des dernières nouvelles concernant Le Moon, notamment par courriel ou par
            SMS ;
          </li>
          <li>Pour élaborer des statistiques commerciales ;</li>
          <li>L’organisation de jeux concours et de loteries publicitaires ;</li>
          <li>La diffusion de contenus publicitaires via le site internet Le Moon ;</li>
          <li>Toute autre utilisation qui permet d’améliorer le fonctionnement du site internet Le Moon et/ou de tout
            autre service fourni par Le Moon ;
          </li>
          <li>Pour gérer les impayés et les contentieux.</li>
        </ul>

        <h3>12.4. Durée de conservation</h3>
        <p>Les données personnelles de l’Utilisateur en relation avec la réservation sur le site Le Moon sont
          conservées pendant une durée de trois (3) ans à compter de leur collecte.</p>
        <p>Les statistiques de mesures d’audience ne sont pas conservées pour une durée supérieure à treize (13) mois.
          Toutefois, à l’issue des délais précités, en ce compris en tant que de besoin à compter de la demande de
          suppression de l’Utilisateur, ses données personnelles pourront faire l’objet d’un archivage intermédiaire
          afin que Le Moon puisse satisfaire à ses obligations légales de conservation :</p>
        <ul>
          <li>Le contrat conclu dans le cadre d’une relation commerciale sera conservé cinq (5) ans à compter de sa
            conclusion ;
          </li>
          <li>Le contrat conclu par voie électronique d’un montant supérieur ou égal à cent vingt (120) euros sera
            conservé deux (2) ans à compter de sa conclusion ;
          </li>
          <li>Les documents relatifs à la garantie pour les biens ou services fournis au consommateur seront conservés
            deux (2) ans à compter de sa souscription ;
          </li>
          <li>Les documents bancaires seront conservés cinq (5) ans à compter de leur communication ;</li>
          <li>Les documents relatifs à la gestion des commandes seront conservés dix (10) ans ;</li>
          <li>Les documents relatifs à la gestion de la facturation seront conservés dix (10) ans.</li>
        </ul>
        <p>En cas de procédure contentieuse, toutes informations, documents et pièces contenant des données personnelles
          de l’Utilisateur tendant à établir les faits litigieux peuvent être conservés pour la durée de la procédure, y
          compris pour une durée supérieure à celles indiquées ci-dessus.</p>
        <p>Certaines données pourront être archivées au-delà des durées prévues pour les besoins de la recherche, de la
          constatation et de la poursuite des infractions pénales dans le seul but de permettre, en tant que besoins, la
          mise à disposition de ces données à l’autorité judiciaire. L’archivage implique que ces données soient
          anonymisées et ne soient plus consultables en ligne mais soient extraites et conservées sur un support
          autonome et sécurisé.</p>

        <h3>12.5. Droit des Utilisateurs</h3>
        <p>Vus disposez du droit d’accès, de rectification, d’opposition, de limitation, de portabilité et/ou, de
          suppression des données nominatives vous concernant se trouvant en notre possession ainsi que de définir des
          directives relatives au sort desdites données après votre décès.</p>
        <p>Pour exercer l’un de ses droits, il suffit à l’Utilisateur d’écrire à l’adresse suivante : Le Moon 23, Rue
          des Lombards – 75004 Paris, en indiquant ses nom, prénom et adresse de courrier électronique. À tout moment,
          l’Utilisateur peut accéder à ses données à caractère personnel et les mettre à jour, ou supprimer son compte
          et les informations personnelles attachées grâce à son identifiant et à son mot de passe.</p>

        <h3>12.6. Destinataire des données</h3>
        <p>L’Utilisateur accepte également que ses données soient transmises à et traitées par l’une quelconque des
          sociétés affiliées (le terme « société affiliée » aura le sens défini à l’article L 233-3 du Code de commerce)
          ou toute autre entité si le transfert de ces données se révèle nécessaire à l’exécution des services par Open
          Flats.</p>
        <p>En outre, l’Utilisateur accepte que ses données à caractère personnel soient transmises et traitées par les
          partenaires d’Le Moon identifiés dans le tableau ci-dessous.</p>
        <p>Le Moon exige de tous ses sous-traitants et prestataires qu’ils mettent en œuvre toute mesure technique
          nécessaire, de façon continue, pour garder les données personnelles de l’Utilisateur de manière sécurisée et
          assurer le même niveau élevé de protection des données personnelles que celui exigé par Le Moon.</p>
        <p>A ce titre, lorsque l’entité concernée est située en dehors de l’Union Européenne, ou dans un pays ne
          disposant pas d’une réglementation adéquate au sens du RGPD, Le Moon encadre sa relation contractuelle avec
          cette entité en adoptant un dispositif contractuel approprié.</p>
      </section>
      <section>
        <h3>12.7. Cookies</h3>
        <p>Le site Le Moon utilise des cookies afin d’éviter aux Utilisateurs de saisir leurs identifiants et mots de
          passe à chaque visite et afin de faciliter les échanges.</p>
        <p>Le Moon utilise également des cookies d’analyse d’audience Internet lui permettant de mieux comprendre le
          comportement de ses Utilisateurs. Ces outils collectent des informations et génèrent des rapports sur les
          statistiques d’utilisation du site Le Moon sans que les Utilisateurs individuels soient nécessairement
          identifiés personnellement par le tiers qui fournit ce cookie (par ex. Google).</p>
        <p>Outre la génération de rapports sur les statistiques d’utilisation du site Le Moon, ces cookies d’analyse
          d’audience permettent également, en association avec certains autres cookies publicitaires (placés par des
          sites et/ou des contenus tiers lorsque l’Utilisateur y accède), d’afficher des annonces plus pertinentes sur
          le Web.</p>
        <p>Chaque Utilisateur conserve la possibilité de refuser les cookies en configurant son navigateur Internet
          :</p>
        <ul>
          <li>Internet Explorer™ : <a
              href="http://windows.microsoft.com/fr-fr/internet-explorer/use-tracking-protection#ie=ie-11">http://windows.microsoft.com/fr-fr/internet-explorer/use-tracking-protection#ie=ie-11</a>
          </li>
          <li>Safari™ : <a href="http://support.apple.com/kb/PH11952">http://support.apple.com/kb/PH11952</a></li>
          <li>Chrome™: <a href="https://support.google.com/chrome/answer/114836?">https://support.google.com/chrome/answer/114836?</a>
          </li>
          <li>Firefox™: <a href="https://support.mozilla.org/fr/kb/comment-activer-option-ne-pas-pister">https://support.mozilla.org/fr/kb/comment-activer-option-ne-pas-pister</a>
          </li>
          <li>Opera™: <a href="http://help.opera.com/Windows/12.10/fr/notrack.html">http://help.opera.com/Windows/12.10/fr/notrack.html</a>
          </li>
          <li>Edge™: <a href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy">https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy</a>
          </li>
        </ul>

        <p>12.8. Dans le cadre de son activité, Le Moon collecte, traite et stocke des données statistiques
          relatives à l’utilisation du site Le Moon et relatives aux activités des Utilisateurs.</p>

        <p>12.9. Ces données d’utilisation, qui peuvent être transmises à des tiers, sont anonymes et ne permettent en
          aucun cas d’identifier les internautes, même de manière indirecte.</p>

        <h2>Article 13. Modifications des conditions générales</h2>
        <p>13.1. Toute modification des Conditions Générales ainsi que des tarifs de location et autres frais seront
          disponibles sur <a href="https://www.moonlocation.com/">https://moonlocation.com/</a>.</p>
        <p>13.2. En effectuant une réservation, vous reconnaissez avoir pris connaissance des tarifs en vigueur et des
          Conditions Générales applicables à ce moment et les accepter.</p>

        <h2>Article 14. Convention de preuve – Droit applicable – Litiges</h2>
        <p>14.1. Tout litige qui n’aurait pas été réglé à l’amiable sera régi par le droit français et sera soumis à la
          compétence non exclusive des tribunaux français. Toutefois, dans le cas de litiges avec un Utilisateur qui ne
          serait pas considéré comme un consommateur au sens de l’article préliminaire du code de la consommation,
          ceux-ci seront soumis à la compétence exclusive des Tribunaux de Paris.</p>
        <p>14.2. Les registres informatisés seront conservés dans les systèmes informatiques d’Le Moon dans des
          conditions raisonnables de sécurité et seront considérés comme les preuves des échanges, des commandes et des
          paiements intervenus sur le site internet Le Moon ou par courrier électronique.</p>
      </section>
    </article>
  </div>
  <Footer/>
</template>

<script>
import NavBar from "@/components/Navbar";
import Footer from "@/components/Footer";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Conditions",
  components: {Footer, NavBar}
}
</script>

<style scoped>
.conditions-utilisation {
  font-family: Arial, sans-serif;
  padding: 20px;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

h2, h3, h4 {
  margin-top: 20px;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
  line-height: 1.5;
}

ul {
  list-style-type: disc;
  padding-left: 20px;
}

li {
  margin-bottom: 10px;
}
</style>
