<template>
  <NavBar/>
  <div class="container-fluid mt-3">
    <div class="row">
      <!-- Grande image à gauche -->
      <div class="col-md-6">
        <div v-if="filteredPhotos.length > 0">
          <img
              :src="getPhotoUrl(filteredPhotos[0].imageName)"
              :alt="filteredPhotos[0].imageName"
              class="img-fluid img-left max-height-img"
          />
        </div>
      </div>
      <!-- Deux colonnes de deux images à droite -->
      <div class="col-md-6">
        <div v-for="(photo, index) in filteredPhotos.slice(1, 3)" :key="index" class="d-none d-md-block">
          <div class="row">
            <div class="col-6">
              <img
                  :src="getPhotoUrl(photo.imageName)"
                  :alt="photo.imageName"
                  class="img-fluid img-right max-height-img"
              />
            </div>
            <div class="col-6" v-if="index + 3 < filteredPhotos.length">
              <img
                  :src="getPhotoUrl(filteredPhotos[index + 3].imageName)"
                  :alt="filteredPhotos[index + 3].imageName"
                  class="img-fluid img-right max-height-img"
              />
            </div>
          </div>
        </div>
        <button class="btn btn-primary float-right mt-3 airbnb-btn" type="button" data-bs-toggle="modal" data-bs-target="#myModal">Afficher toutes les photos</button>
      </div>
    </div>
  </div>

  <div class="modal fade" id="myModal">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div v-for="(photo, index) in filteredPhotos" :key="index" class="col-md-4">
              <img
                  :src="getPhotoUrl(photo.imageName)"
                  :alt="photo.imageName"
                  class="img-fluid max-height-img mb-3 rounded-5"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      {{ logement.miseenavant }}
      <div class="col-md-4 mt-5">
        <!-- Utilisez le composant Api pour afficher le titre et la description du logement -->
        <div class="sticky">
          <div
              id="guestready-booking-widget__root"
              :data-rental="logement.rentalready"
              data-locale="fr"
              data-reservation-platform-id="132"
              data-base-url="https://openflats.rentalready.io"
          ></div>
        </div>
      </div>
      <!-- Colonne fixe à gauche (8 colonnes) -->
      <div class="col-md-8 mt-5">
        <LogementInfos :logement="logement"/>
      </div>
    </div>
  </div>
  <!--  </div>-->
  <Suggestions/>
  <Footer/>
</template>

<script>
import {onMounted, ref, computed} from "vue";
import axios from "axios";
import NavBar from "@/components/Navbar";
import Footer from "@/components/Footer";
import "vue3-carousel/dist/carousel.css";
import LogementInfos from "@/components/Logement/LogementInfos";
import Suggestions from "@/components/Logement/Suggestions";

export default {
  name: "LogementDetailsV2",
  components: { Suggestions, LogementInfos, Footer, NavBar},
  props: {
    id: String,
  },
  setup(props) {
    const isScriptLoaded = ref(false);

    const logement = ref({});
    const logementPhotos = ref([]);
    const isLoading = ref(true);

    onMounted(async () => {
      try {
        await fetchLogement(props.id);
        await fetchLogementPhotos(props.id);
        await loadBookingScript();
        isLoading.value = false;
      } catch (error) {
        console.error("An error occurred:", error);
      }
    });

    const loadBookingScript = () => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = 'https://book.guestready.com/booking_widget/main.js';
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    };

    const fetchLogement = async (logementId) => {
      try {
        const response = await axios.get(
            `https://www.api-moon.moonlocation.com/api/logements/${logementId}`
        );
        const logementData = response.data;
        logement.value = {
          titre: logementData.titre,
          description: logementData.description,
          rating: logementData.rating,
          superficie: logementData.superficie,
          chambres: logementData.chambres,
          capacity: logementData.capacity,
          rentalready: logementData.rentalready,
          descriptionlg: logementData.descriptionlg,
          salledebains: logementData.salledebains,
          lits: logementData.lits,
          misenavant: logementData.misenavant,
        };
      } catch (error) {
        console.error(
            "Erreur lors de la récupération des détails du logement",
            error
        );
      }
    };

    const fetchLogementPhotos = async (logementId) => {
      try {
        const response = await axios.get(
            `https://www.api-moon.moonlocation.com/api/photos?logementId=${logementId}`
        );
        const photosData = response.data["hydra:member"];
        logementPhotos.value = photosData.map((photo) => ({
          imageName: photo.imageName,
          id: photo.id,
          logementId: photo.idLogements,
        }));
      } catch (error) {
        console.error(
            "Erreur lors de la récupération des photos du logement",
            error
        );
      }
    };

    const getPhotoUrl = (imageName) => {
      return `https://www.api-moon.moonlocation.com/images/photos/${imageName}`;
    };

    const extractLogementId = (logementUrl) => {
      const matches = logementUrl.match(/\/api\/logements\/(\d+)/);
      if (matches && matches.length > 1) {
        return parseInt(matches[1], 10);
      } else {
        return null;
      }
    };

    // Filtrer les photos en fonction de l'ID du logement spécifié dans le routeur
    const filteredPhotos = computed(() => {
      return logementPhotos.value.filter(
          (photo) => extractLogementId(photo.logementId) === parseInt(props.id)
      );
    });

    return {
      logement,
      filteredPhotos, // Utilisez les photos filtrées dans le template
      isLoading,
      getPhotoUrl,
      extractLogementId,
      isScriptLoaded,
    };
  },
};
</script>

<style scoped>

.img-left {
  object-fit: cover;
  margin-top: 10px;
  width: 100%;
  border-radius: 15px;
}

.img-right {
  margin-top: 12px;
  object-fit: cover;
  height: 84.8%;
  width: 100%;
  border-radius: 15px;
}


.sticky {
  position: sticky;
  top: 20px; /* Ajustez la valeur en fonction de la distance souhaitée depuis le haut */
  z-index: 100; /* Si nécessaire, ajustez la valeur du z-index */
  /* Ajoutez d'autres styles CSS personnalisés selon vos besoins */
}

/* Styles CSS pour le bouton Airbnb */
.airbnb-btn {
  background-color: #3575A0;
  color: #fff;
  font-weight: bold;
  padding: 10px 20px;
  border: 2px solid;
  border-radius: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.airbnb-btn:hover {
  background-color: white;
  color: #3575A0;
}

</style>