<template>
  <div>
    <p>
      Le ménage est compris dans le prix de la location, merci simplement de respecter les lieux de la maison :) A votre
      arrivée les lits seront faits et tout le linge de maison est fournis sur place (draps, housses, serviettes de
      toilettes etc..) Vous n'avez plus qu'à poser vos valises et profiter de votre séjour
    </p>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Autres"
}
</script>

<style scoped>

</style>