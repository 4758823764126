<template>
  <section class="section mt-5">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <h1 class="display-4">Contactez-nous</h1>
          <form ref="form" @submit.prevent="sendEmail">
            <div class="form-group">
              <input class="form-control" type="name" name="name" placeholder="Nom et prénom">
            </div>
            <div class="form-group">
              <input class="form-control" type="phone" name="telephone" placeholder="Téléphone">
            </div>
            <div class="form-group">
              <input class="form-control" type="email" name="from_name" placeholder="Mail">
            </div>
            <div class="form-group">
              <textarea class="form-control" name="message" placeholder="Message"></textarea>
            </div>
            <button class="btn custom-btn" type="submit">Envoyer</button>
          </form>
        </div>
        <div class="col-md-6">
          <!-- Content for the second column (if any) -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import emailjs from '@emailjs/browser';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Formulaire",
  methods: {
    sendEmail() {
      emailjs.sendForm('service_03zwp29', 'template_lbfx5g5', this.$refs.form, 's0HROh6DHoUdLLqy6')
          .then((result) => {
            console.log('SUCCESS!', result.text);
            return alert('message envoyé avec succès');
          }, (error) => {
            console.log('FAILED...', error.text);
          });
    }
  }
}
</script>

<style scoped>
section{
  padding-bottom: 3rem;
}
.custom-btn {
  color: #3575A0;
  border-color: #3575A0;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
  margin: 1rem;
}

.custom-btn:hover {
  background-color: #3575A0;
  color: white;
  border-color: white;
}

input:focus {
  border: solid 2px #3575A0;
  outline: none;
  box-shadow: none;
}

textarea:focus {
  border: solid 2px #3575A0;
  outline: none;
  box-shadow: none;
}

textarea {
  resize: both;
  height: 100px;
}

</style>