<template>
  <section>
    <div class="container">
      <div class="row">
        <div v-for="(column, index) in columns" :key="index" class="col-md-6">
          <div :class="`card ${column.bgColor} text-center`">
            <div class="card-body">
              <div class="icon-bar">
                <i v-for="(icon, iconIndex) in column.icons" :key="iconIndex" :class="icon"></i>
              </div>
              <h1>{{ column.title }}</h1>
            </div>
          </div>
          <h3 class="mt-5">{{ column.subtitle }}</h3>
          <h4 class="mt-4 mb-5">{{ column.description }}</h4>
          <p v-for="(item, itemIndex) in column.items" :key="itemIndex"><i
              class="fas fa-check text-success mr-3"></i>{{ item }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Service",
  data() {
    return {
      columns: [
        {
          bgColor: "bg-yellow",
          icons: ["fas fa-list-alt fa-3x text-primary", "fas fa-camera fa-3x text-success", "fas fa-dollar-sign fa-3x text-danger"],
          title: "Votre annonce sur nos plateformes de réservation",
          subtitle: "On rédige votre annonce avec un texte accrocheur, accompagné de belles photos.",
          description: "Nous diffusons l'annonce sur les plateformes partenaires (Airbnb, Booking, Abritel) et nous faisons varier le prix des nuitées tous les jours pour saisir toutes les opportunités",
          items: ["Des demandes de réservations plus importantes", "Une visibilité augmentée", "Des revenus maximisés"]
        },
// Ajoutez ici les données pour la deuxième colonne
        {
          bgColor: "bg-pink",
          icons: ["fas fa-key fa-3x text-info", "fas fa-shield-alt fa-3x text-warning", "fas fa-lock fa-3x text-danger"],
          title: "Vos clés",
          subtitle: "Sécurité maximale pour votre propriété.",
          description: "Nous gérons les clés de votre propriété et assurons une sécurité totale.",
          items: ["Accès sécurisé", "Gestion des clés", "Surveillance 24/7"]
        },
        {
          bgColor: "bg-green",
          icons: ["fas fa-broom fa-3x text-info", "fas fa-tshirt fa-3x text-warning", "fas fa-soap fa-3x text-danger"],
          title: "Le ménage et le linge",
          subtitle: "Nos équipes de ménage interviennent avant et après chaque voyageur.",
          description: "Pas la peine de laisser votre linge ! On fournit les draps, serviettes, torchons, savons, shampoing, sacs poubelle, papier toilette… On gère vraiment tout !",
          items: ["Votre logement reste propre", "Pas de lessive à faire quand vous revenez !"]
        },
        {
          bgColor: "bg-orange",
          icons: ["fas fa-user-check fa-3x text-info", "fas fa-clock fa-3x text-warning", "fas fa-hotel fa-3x text-danger"],
          title: "Les voyageurs",
          subtitle: "Au moindre problème voyageur, notre assistance intervient 24/7. On gère le check-in et le check-out en mode hôtel 5* !",
          description: "",
          items: ["Leurs commentaires 5* seront visibles", "Ils se souviendront de leur séjour chez vous et ils reviendront ..."]
        },
        {
          bgColor: "bg-blue",
          icons: ["fas fa-shield-alt fa-3x text-info", "fas fa-umbrella fa-3x text-warning", "fas fa-hand-holding-usd fa-3x text-danger"],
          title: "La caution et les assurances",
          subtitle: "En cas de problème, on mobilise la caution des voyageurs, l'assurance hôte Airbnb et notre propre assurance.",
          description: "On est sur le pont 24/7 pour résoudre tous les éventuels problèmes.",
          items: ["Un dépôt de garantie demandé pour chaque réservation", "Aucune formalité administrative à gérer de votre côté", "Vous partez en mode sérénité"]
        },
        {
          bgColor: "bg-red",
          icons: ["fas fa-tools fa-3x text-info", "fas fa-headset fa-3x text-warning", "fas fa-smile fa-3x text-danger"],
          title: "La maintenance et l’assistance 24/7",
          subtitle: "Un problème rencontré par un voyageur, nos pros de l’assistance interviennent 24/7.",
          description: "Nos techniciens veillent à la bonne tenue de votre logement.",
          items: ["Vous n'avez à vous préoccuper de rien !", "Des voyageurs comblés"]
        }
        // Ajoutez ici les données pour d'autres colonnes si nécessaire
      ]
    };
  }
};
</script>

<style scoped>

section{
  margin-bottom: 5rem;
}

.card{
  border-radius: 15px;
  border: none;
}

h1 {
  font-size: 2.5rem;
  margin-top: 2rem;
  font-weight: 700;
}

h3 {
  font-size: 18px;
  font-weight: 700;
}

h4 {
  font-size: 18px;
  font-weight: 400;
}

.icon-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 0 auto;
}

.bg-red{
  background-color: rgb(255 192 160);
}

.bg-blue{
  background-color: rgb(189 209 255);
}

.bg-green{
  background-color: rgb(194 249 223);
}

.bg-yellow{
  background-color: rgb(255 246 198);
}

.bg-pink{
  background-color: rgb(255 208 217);
}

.bg-orange{
  background-color: rgb(255 219 158);
}
/* Ajoutez ici vos styles CSS pour les classes de couleur (bg-yellow, bg-pink, etc.) si nécessaire */
</style>
