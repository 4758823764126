<template>
  <section class="section bg-light text-white py-5">
    <div class="container-fluid col-md-11">
      <div class="card">
        <div class="card-body d-md-flex">
          <!-- Colonne d'image (pour les grands écrans) -->
          <div class="col-md-4">
            <img
                src="../assets/salon.jpg"
                class="card-img" alt="...">
          </div>

          <!-- Colonne de texte -->
          <div class="col-md-8">
            <h1 class="display-4">L'hébergement parfait</h1>
            <p class="subtitle text-white">Nous proposons dans nos appartements, du studio au 5 pièces, une convivialité
              sans pareil. Nos équipes vous offrent en plus une assistance 7j / 7 et de 9h à 22h par message ou par
              téléphone, afin d'être présentes pour vous, lorsque vous en avez besoin.</p>
            <router-link to="/proprietaire">
              <button class="btn btn-outline-success custom-btn">En savoir plus sur Le Moon</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Satisfaction"
}
</script>

<style scoped>

img{
  border-radius: 5%;
}
.display-4 {
  font-weight: 600;
  color: white;
}

.subtitle {
  font-size: 18px;
  font-weight: 400;
}

h3 {
  font-size: 24px;
  font-weight: 700;
}

h4 {
  font-size: 18px;
  font-weight: 300;
  opacity: 75%;
}

.card {
  border: none;
  background-color: #3575A0;
  padding-top: 3rem;
  padding-bottom: 3rem;
  border-radius: 25px;
}

section {
  margin-bottom: 2rem;
}

.custom-btn {
  color: white;
  border-color: white;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

.custom-btn:hover {
  background-color: white;
  color: #3575A0;
}

img {
  max-height: 300px;
}
</style>
