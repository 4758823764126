<template>
  <section class="section bg-light text-white py-5">
    <div class="container-fluid">
      <div class="container-fluid col-md-10 text-center">
        <h1 class="display-4 text-dark">Types de bien</h1>
        <p class="subtitle text-dark">Trouvez l'hébergement qui correspond à vos attentes.</p>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-3 mb-3">
          <router-link to="/logements">
            <div class="card text-bg-dark">
              <div class="image-container">
                <img
                    src="../../public/appartements/scribe.jpeg"
                    class="card-img" alt="...">
                <div class="zoom-overlay">
                  <span class="zoom-text">Studio</span>
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-3 mb-3">
          <router-link to="/logements">
            <div class="card text-bg-dark">
              <div class="image-container">
                <img
                    src="../../public/appartements/jda2d.webp"
                    class="card-img" alt="...">
                <div class="zoom-overlay">
                  <span class="zoom-text">2 pièces</span>
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-3 mb-3">
          <router-link to="/logements">
            <div class="card text-bg-dark">
              <div class="image-container">
                <img
                    src="../../public/appartements/mathurins.webp"
                    class="card-img" alt="...">
                <div class="zoom-overlay">
                  <span class="zoom-text">3 pièces</span>
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-3 mb-3">
          <router-link to="/logements">
            <div class="card text-bg-dark">
              <div class="image-container">
                <img
                    src="../../public/appartements/CARDINET.webp"
                    class="card-img" alt="...">
                <div class="zoom-overlay">
                  <span class="zoom-text">4 pièces et plus</span>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Type",
};
</script>

<style scoped>

section {
  margin-bottom: 2rem;
}

.display-4 {
  font-weight: 600;
}

.subtitle {
  font-size: 18px;
  font-weight: 300;
}

h3 {
  font-size: 24px;
  font-weight: 700;
}

h4 {
  font-size: 18px;
  font-weight: 300;
  opacity: 75%;
}

.card {
  border: none;
  border-radius: 15px;
}

.image-container {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
}

.card-img {
  display: block;
  max-width: 100%;
  height: 30rem; /* Hauteur fixe souhaitée */
  object-fit: cover; /* Assure le redimensionnement correct de l'image */
  transition: transform 0.3s ease;
}


.zoom-overlay {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: white;
  padding: 5px 10px;
  border-radius: 5px;
  opacity: 1; /* Afficher le bouton en permanence */
}

.zoom-overlay span.zoom-text {
  display: inline-block; /* Ajuster la largeur au contenu */
}

.zoom-text {
  display: block;
  text-align: center;
  color: black;
  font-size: 18px;
  font-weight: 500;
  height: 2rem;
  width: 6rem;
}

.image-container:hover .card-img {
  transform: scale(1.1);
}

</style>
