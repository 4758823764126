<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  components: {}
}
</script>

<style>

body {
  background-color: #f8f9fa !important;
}

p {
  font-family: Helvetica, sans-serif;
}

.title{
  font-family: Helvetica, sans-serif;
}

html, body {
  margin: 0;
  padding: 0;
}

</style>
